.title {
  margin: 10px 10px -8px 10px;
  font-size: 16px;
}

.divider {
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  height: 1px;
  border-bottom: 1px solid #dcdcdc;
}
