.footer {
  background-color: #2c2c2c;
  border-top: 3px solid #f16522;
}

.rightPanel {
  width: 385px;
  margin-left: auto;
  color: rgb(181 181 181);
  padding-right: 20px;
}

.iflowTitle {
  font-size: 16px;
  padding: 10px 0;
  font-weight: bold;
  color: white;
}

.content div {
  font-size: 10px;
}

.content a {
  font-size: 10px;
  color: rgb(181 181 181);
  text-decoration: none;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.content a:hover {
  color: #f16522;
}

.terms {
  font-size: 10px;
  font-weight: bold;
  color: rgb(181 181 181);
  padding: 3px 0 8px 0;
}

.terms a {
  font-size: 10px;
  font-weight: bold;
  color: rgb(181 181 181);
  text-decoration: none;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.terms a:hover {
  color: #f16522;
}

.terms span {
  font-size: 10px;
  font-weight: bold;
  color: rgb(181 181 181);
  text-decoration: none;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  cursor: pointer;
}

.terms span:hover {
  color: #f16522;
}
