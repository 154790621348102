.container {
  margin-top: 15px;
}

.stepContainer {
  display: flex;
  margin-top: 10px;
}

.stepNumber {
  background-color: #a1a1a1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  width: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;
}

.stepContent {
  margin-left: 20px;
}
