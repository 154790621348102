.container {
  border-radius: 7px;
  border: 2px solid #8dc63f;
  display: flex;
  padding: 7px 15px;
  margin: 10px 0px;
}

.text {
  margin-left: 12px;
  font-weight: 500;
  color: #212121;
}

.icon {
  margin-top: 2px;
  width: 20px;
  height: 20px;
}
