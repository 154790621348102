.containerCard {
  min-height: 200px;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
}

.info {
  font-size: 14px;
  color: #424242;
  line-height: 1.5;
  font-weight: 400;
}

.title {
  font-size: 12px;
  font-weight: 400;
  color: #AAAAAA;
}

.section {
  margin-bottom: 15px;
}
