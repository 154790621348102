.MuiFormHelperText-root {
  color: red !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
footer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

main {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: #f7f7f7;
  /* background-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(90deg, #f9f9f9 18%, #c9c9c9 51%);
  background-position: center top;
  background-repeat: no-repeat;
  border-width: 0px 0px 0px 0px;
  border-color: #e2e2e2;
  border-style: solid; */
}

