.container {
  display: flex;
  margin: -15px 0px -17px 0px;
}



.postalWorkerContainer {
  display: flex;
  align-items: center;
}

.postalWorker {
  width: 120px;
  margin-right: 25px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #424242;
}

.description {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
  color: #424242;
  font-weight: 400;
  margin-bottom: 10px;
}
