.container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.title {
  font-size: 22px;
  margin: 15px;
  margin-bottom: 50px;
}

.buttonContainer {
  margin-top: 20px;
}

.errorMessage {
  color: red;
}

.descriptionInput {
  width: 100%;
}

.divider {
  height: 1px;
  border-top: 1px solid #c7c7c7;
  margin: 50px 100px;
}

.addTrackingContainer {
  margin-top: 20px;
}

.textCheckbox {
  text-align: center;
}

.saveTextField {
  width: 100%;
  max-width: 360px;
  background-color: white;
}

.helpTextField{
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
  padding: 5px;
}
