.container {
  margin-top: 4px;
}

.stateContainer {
  font-size: 14px;
  color: #424242;
  line-height: 1.5;
  min-height: 20px;
  margin-bottom: 2px;
  font-weight: 400;
}

.title {
  font-weight: 600;
  color: #424242;
  font-size: 14px;
  line-height: 1.5;
}
