.containerCard {
  margin: 20px 10px 0 10px;
  box-sizing: border-box;
  position: relative;
}

.spinnerContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  padding: 16px 20px 10px 30px;
  color: #424242;
  display: flex;
}

.content {
  padding: 20px 20px 20px 30px;
}

.iconContainer {
  width: 40px;
  margin-top: 1px;
  /* color: #8e8e8e; */
  color: #f26523;
}
