.centered {
  max-width: 1000px;
  margin: 30px auto 50px auto;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
}

.helpButton {
  display: none;
}

@media (max-width: 900px) {
  .cardsContainer {
    flex-direction: column;
  }
  .helpButton {
    display: block;  /* En pantallas pequeñas, el elemento se muestra */
    position: fixed;
    bottom: 20px;
    right:20px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .helpIcon{ 
    font-size: 70; 
    color: rgb(141, 198, 63) 
  }
}

.column {
  flex: 1;
}

.title {
  text-align: left;
  font-size: 32px;
  margin: 40px 0 -10px 10px;
  font-weight: 500;
  color: #424242
}
