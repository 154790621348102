.container {
  margin: -12px -10px 12px -15px;
}

.richText {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #424242;
}

.richText p,
h1,
h2,
h3,
ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
}

.richText a {
  color: #f26523;
  font-weight: bold;
}

.richText p span[class="ql-size-small"] {
  font-size: 12px;
}

.richText p span[class="ql-size-large"] {
  font-size: 20px;
}

.richText p span[class="ql-size-huge"] {
  font-size: 24px;
}

.richText p strong[class="ql-size-small"] {
  font-size: 12px;
}

.richText p strong[class="ql-size-large"] {
  font-size: 20px;
}

.richText p strong[class="ql-size-huge"] {
  font-size: 24px;
}

.richText p u[class="ql-size-small"] {
  font-size: 12px;
}

.richText p u[class="ql-size-large"] {
  font-size: 20px;
}

.richText p u[class="ql-size-huge"] {
  font-size: 24px;
}

.richText p em[class="ql-size-small"] {
  font-size: 12px;
}

.richText p em[class="ql-size-large"] {
  font-size: 20px;
}

.richText p em[class="ql-size-huge"] {
  font-size: 24px;
}

.richText p a[class="ql-size-small"] {
  font-size: 12px;
}

.richText p a[class="ql-size-large"] {
  font-size: 20px;
}

.richText p a[class="ql-size-huge"] {
  font-size: 24px;
}
