.container {
  margin-top: 20px;
}
.pointsContainer {
  display: flex;
}

.question {
  font-size: 16px;
  margin-bottom: 15px;
}

.pointSquare {
  width: 38px;
  height: 38px;
}

.multiline {
  width: 100%;
  margin-top: 15px !important;
}

.buttonContainer {
  text-align: right;
  margin-top: 25px;
}

.sendingLoaderContainer {
  min-height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.responseSentContainer {
  display: flex;
  margin-top: -15px;
  margin-bottom: -15px;
}

.thanksLogoContainer {
  display: flex;
  align-items: center;
}

.thanksLogo {
  width: 120px;
  margin-top: 5px;
}

.bigGreeting {
  font-size: 24px;
  color: #424242;
  font-weight: 500;
  line-height: 20px;
  line-height: 36px;
}

.greetingDetail {
  color: #424242;
  margin: 5px 20px 14px 0px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}

.comments {
  font-size: 12px;
  color: #5f5f5f;
  margin-top: 4px;
}

.title {
  font-size: 24px;
  font-weight: 500;
  padding: 10px 30px;
  color: #424242;
  display: flex;
  margin: -40px -22px -8px -30px;
}

.subtitle {
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 15px;
}
