.container {
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #f16522;
}

.iflowLogo {
  width: 250px;
  margin: 10px 20px;
  cursor: pointer;
}

.title {
  font-size: 22px;
  color: white;
  margin-left: 20px;
  cursor: pointer;
}
