.container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid #e0e0e0;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
}

.row {
  display: flex;
}
